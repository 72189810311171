import React from "react"

import styled from "@emotion/styled"
import { Global } from "@emotion/core"

import Menu from "./menu"
import { globalStyles } from "../styles/globalStyles"
import "../styles/calendar.css"

const Root = styled.div`
  font-family: ${(props: { theme: any }) => props.theme.fonts.body};
  font-weight: ${props => props.theme.fonts.normalWeight};
  padding: 60px 1.0875rem 1.45rem;
  margin: 0 auto;
  max-width: 960px;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${props => props.theme.fonts.heading};
  }
`

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Menu />
      <Root>
        <main>{children}</main>
        <footer>
          © {new Date().getFullYear()}. Icon adapted from Christmas by James
          Kopina (Noun Project)
        </footer>
      </Root>
      <Global styles={globalStyles} />
    </>
  )
}

export default Layout
